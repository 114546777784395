<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      賣貨紀錄

      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        新增賣貨紀錄
      </v-btn>
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" sm="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filterQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              v-bind="attrs"
              v-on="on"
              label="日期區間"
              outlined
              readonly
              dense
              hide-details
              clearable
              class="dates-input"
              @click:clear="filterQuery.date = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(filterQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3" v-if="accessBranch.length > 1">
        <v-select
          label="店點"
          placeholder="店點"
          v-model="filterQuery.branch"
          :items="branches"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <div class="font-weight-bold">金額總計：{{ dataAmountSum }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.sum="{ item }">
            {{ formatPrice(item.sum) || formatPrice(item.income) || "" }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ dayjs(item.createdAt).format("YYYY/MM/DD") }}
          </template>
          <!-- <template v-slot:item.action="{ item }">
            <div class="my-1">
              <v-btn
                class="my-1 mx-2"
                color="primary"
                @click="openDialog('edit', item)"
              >
                編輯
              </v-btn>
            </div>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCreateTrade
      v-if="dialog.open"
      v-model="dialog.open"
      :dialog="dialog"
      :trade="payload"
      :products="products"
      :stockMap="stockMap"
      @load="load"
    />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import DialogCreateTrade from "@/components/dashboard/trade/DialogCreateTrade.vue";

export default {
  name: "Trade",
  mixins: [util],
  components: { DialogCreateTrade },
  data: () => ({
    dayjs,
    trade: [],
    products: [],
    payload: {
      branch: null,
      product: null,
      amount: 0,
    },
    dialog: { type: "create", open: false },
    menu: false,
    filterQuery: {
      date: [],
      keyword: "",
      branch: "",
    },
    headers: [
      {
        text: "日期",
        value: "createdAt",
        align: "center",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "店點",
        value: "branch",
        align: "center",
        sortable: false,
      },
      {
        text: "商品",
        value: "product.name",
        align: "left",
        sortable: false,
      },
      {
        text: "商品單價",
        value: "product.price",
        align: "right",
        sortable: false,
      },
      {
        text: "數量",
        value: "amount",
        align: "right",
        sortable: false,
      },
      {
        text: "總金額",
        value: "sum",
        align: "right",
        sortable: false,
      },
      {
        text: "建立者",
        value: "user",
        align: "left",
        sortable: false,
      },
      // {
      //   text: "操作",
      //   value: "action",
      //   align: "center",
      //   sortable: false,
      // },
    ],
  }),
  computed: {
    _: () => _,
    branches() {
      return [{ text: "全部", value: "" }, ...this.accessBranch];
    },
    formattedDate() {
      const date = this.filterQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
    dataAmountSum() {
      return this.filterData.reduce((amount, record) => {
        if (record.sum) {
          amount += record.sum;
        }
        if (record.income) {
          amount += record.income;
        }
        return amount;
      }, 0);
    },
    filterData() {
      let data = this.trade.sort((a, b) => {
        if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) {
          return -1;
        }
        if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) {
          return 1;
        }
        return 0;
      });
      const { keyword, date, branch } = this.filterQuery;

      if (date.length === 2) {
        data = data.filter((item) =>
          this.isBetween({
            date: item.date,
            start: date[0],
            end: date[1],
          })
        );
      }
      if (keyword) {
        data = data.filter((trade) =>
          trade.product.name?.toUpperCase().includes(keyword.toUpperCase())
        );
      }
      if (branch) {
        data = data.filter((trade) => trade.branch === branch);
      }

      return data;
    },
    stockMap() {
      return _.groupBy(this.stock, "branch");
    },
  },
  async created() {
    await this.load();
    await this.getProduct();
  },
  methods: {
    async load() {
      this.$vloading.show();
      await this.getTrade();
      await this.getStock();
      this.$vloading.hide();
    },
    clear() {
      this.dialog.open = false;
    },
    openDialog(type, payload) {
      this.payload = payload;
      this.dialog.type = type;
      this.dialog.open = true;
    },
    async getTrade() {
      this.$vloading.show();
      // let { data } = await this.axios.get(`/trade`);
      const data = await this.getData(`/trade`);
      this.trade = data;
      this.$vloading.hide();
    },
    async getProduct() {
      this.$vloading.show();
      let { data } = await this.axios.get(`/product`);
      this.products = data;
      this.$vloading.hide();
    },
    async getStock() {
      this.$vloading.show();
      let { data } = await this.axios.get(`/stock`);
      this.stock = data;
      this.$vloading.hide();
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.dates-input {
  min-width: 150px;
}
</style>
