<template>
  <v-dialog v-model="open" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">
        {{ dialog.type === "create" ? "新增賣貨紀錄" : "編輯賣貨紀錄" }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <!-- <v-col cols="12">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.date"
                  label="日期"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.date"
                no-title
                scrollable
                locale="zh-tw"
              >
              </v-date-picker>
            </v-menu>
          </v-col> -->
          <v-col cols="12">
            <v-select
              v-model="payload.branch"
              :items="branches"
              label="店點"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              label="商品"
              v-model="payload.product"
              :items="productsOptions"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="剩餘庫存(參考用)"
              :value="productStock"
              outlined
              dense
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="商品單價(參考用)"
              :value="payload.product?.price || ''"
              outlined
              dense
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="總價(參考用)"
              :value="getSum(payload.product?.price, payload.amount)"
              outlined
              dense
              hide-details
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model.number="payload.amount"
              label="數量"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model.number="payload.income"
              label="總金額"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="open = false" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from "@/mixins/util.vue";

export default {
  name: "DialogCreateTrade",
  mixins: [util],
  props: ["value", "dialog", "trade", "products", "stockMap"],
  data: () => ({
    amount: null,
    payload: {
      branch: null,
      product: null,
      amount: 0,
    },
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    branches() {
      return this.accessBranch;
    },
    productStock() {
      if (!this.payload.product?._id || !this.payload.branch) {
        return null;
      }
      return this.branchStockMap[this.payload.product._id]?.amount ?? 0;
    },
    branchStockMap() {
      if (!this.payload.branch) return [];
      return _.keyBy(this.stockMap[this.payload.branch], "productId");
    },
    productsOptions() {
      return this.products.map((product) => ({
        text: product.name,
        value: product,
      }));
    },
    productMap() {
      return _.keyBy(this.products, "_id");
    },
  },
  created() {
    // if (this.trade) this.payload = _.cloneDeep(this.trade);
  },
  methods: {
    async create() {
      const hasMissField = Object.values(this.payload).some(
        (value) => !value && value !== 0
      );

      if (hasMissField) {
        this.$toast.error("請填寫所有欄位");
        return;
      }

      if (!this.productStock || this.productStock < this.payload.amount) {
        this.$toast.error("庫存不足");
        return;
      }

      try {
        this.$vloading.show();
        await this.axios.post(`/trade`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "建立失敗");
      }
      this.$vloading.hide();
    },
    getSum(price, amount) {
      if (!price || !amount) return "---";
      return price * amount;
    },
  },
};
</script>
